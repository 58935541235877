// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ctemplate-account-js": () => import("./../../../src/pages/ctemplate/Account.js" /* webpackChunkName: "component---src-pages-ctemplate-account-js" */),
  "component---src-pages-ctemplate-cuenta-js": () => import("./../../../src/pages/ctemplate/cuenta.js" /* webpackChunkName: "component---src-pages-ctemplate-cuenta-js" */),
  "component---src-pages-ctemplate-herramientas-js": () => import("./../../../src/pages/ctemplate/herramientas.js" /* webpackChunkName: "component---src-pages-ctemplate-herramientas-js" */),
  "component---src-pages-ctemplate-index-js": () => import("./../../../src/pages/ctemplate/index.js" /* webpackChunkName: "component---src-pages-ctemplate-index-js" */),
  "component---src-pages-ctemplate-tools-js": () => import("./../../../src/pages/ctemplate/Tools.js" /* webpackChunkName: "component---src-pages-ctemplate-tools-js" */),
  "component---src-pages-eng-account-js": () => import("./../../../src/pages/eng/Account.js" /* webpackChunkName: "component---src-pages-eng-account-js" */),
  "component---src-pages-eng-index-js": () => import("./../../../src/pages/eng/index.js" /* webpackChunkName: "component---src-pages-eng-index-js" */),
  "component---src-pages-eng-test-js": () => import("./../../../src/pages/eng/Test.js" /* webpackChunkName: "component---src-pages-eng-test-js" */),
  "component---src-pages-eng-tools-js": () => import("./../../../src/pages/eng/Tools.js" /* webpackChunkName: "component---src-pages-eng-tools-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-spa-cuenta-js": () => import("./../../../src/pages/spa/cuenta.js" /* webpackChunkName: "component---src-pages-spa-cuenta-js" */),
  "component---src-pages-spa-herramientas-js": () => import("./../../../src/pages/spa/herramientas.js" /* webpackChunkName: "component---src-pages-spa-herramientas-js" */),
  "component---src-pages-spa-index-js": () => import("./../../../src/pages/spa/index.js" /* webpackChunkName: "component---src-pages-spa-index-js" */)
}

